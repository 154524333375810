import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import history from 'utils/history';

import packageManifest from '../../package.json';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN || '',
    environment: process.env.REACT_APP_ENV || 'undefined',
    release: 'bodyprophet-web@' + packageManifest.version,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],
    normalizeDepth: 10,
    tracesSampleRate: 0.3,
    ignoreErrors: [
      'Non-Error exception captured',
      'Non-Error promise rejection captured',
      'ResizeObserver loop limit exceeded',
    ],
  });
}
