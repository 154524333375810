import { History, LocationDescriptor } from 'history';
import { useLocation } from 'react-router';

import history from './history';

// Refers to https://material.io/design/navigation/understanding-navigation.html#types-of-navigation
//
// 1. Forward navigation refers to moving between screens at consecutive levels of hierarchy.
// Stores referrer location descriptor in `location.state.from` to support chronological reverse navigation.
//
// 2. Lateral navigation refers to moving between screens at the same level of hierarchy.
// Inherits referrer location descriptor in `location.state.from` to support chronological reverse navigation.
//
// 3. Reverse navigation refers to moving backwards through screens either chronologically or hierarchically.
// Uses referrer location from `location.state.from` if exist to apply chronological reverse navigation,
// other way falls back to provided hierarchical location.

export enum NavigationDir {
  Forward = 'forward',
  Lateral = 'lateral',
  Reverse = 'reverse',
}

// Types
//
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type NavigateFnType = (path: string, state?: any, withoutFromSave?: boolean) => void;

export interface INavigationReferrerShape {
  from: LocationDescriptor;
}

// Utils
//
export const navigateForward: NavigateFnType = (path, state, withoutFromSave = false): void => {
  if (withoutFromSave) {
    history.push(path, state);
  } else {
    history.push(path, { ...state, from: history.location });
  }
};

export const navigateLateral: NavigateFnType = (path, state): void => {
  const from = (history.location?.state as any)?.from;
  history.push(path, { ...state, from });
};

export const navigateReverse: NavigateFnType = (fallbackPath, state): void => {
  const from = (history.location?.state as any)?.from;

  if (from) {
    history.push(history.createHref(from), from.state);
  } else {
    history.push(fallbackPath, state);
  }
};

export const directLocationDescriptor = (
  to: History.LocationDescriptor,
  dir: NavigationDir,
): History.LocationDescriptor => {
  if (dir === NavigationDir.Reverse) {
    const from = (history.location?.state as any)?.from;
    return from || to;
  } else {
    const toObject = typeof to === 'object' ? to : { pathname: to };

    if (dir === NavigationDir.Forward) {
      return {
        ...toObject,
        state: {
          ...(toObject.state as object),
          from: history.location,
        },
      };
    } else if (dir === NavigationDir.Lateral) {
      return {
        ...toObject,
        state: {
          ...(toObject.state as object),
          from: (history.location?.state as any)?.from,
        },
      };
    }

    return to;
  }
};

export const useNavigationReferrer = (): LocationDescriptor | undefined => {
  const { state } = useLocation<Partial<INavigationReferrerShape> | undefined>();
  return state?.from;
};
