import React from 'react';

import { Spinner } from 'reactstrap';

const PageLoader: React.FC = () => (
  <div className="w-100 vh-100 d-flex justify-content-center align-items-center page-loader">
    <Spinner color="primary" />
  </div>
);

export default PageLoader;
