import React, { Suspense } from 'react';

import { useOptimizelyLoading } from 'utils/hooks/useOptimizelyLoading';

import { PageLoader } from './components/Loaders';

const App = React.lazy(() => import('./components/boot/App'));

const AppContainer: React.FC = () => {
  const isOptimizelyLoaded = useOptimizelyLoading();

  if (!isOptimizelyLoaded) return <PageLoader />;

  return (
    <Suspense fallback={<PageLoader />}>
      <App />
    </Suspense>
  );
};

export default AppContainer;
