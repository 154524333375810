import { useEffect, useState } from 'react';

import { optimizely } from 'services/optimizely';

export const useOptimizelyLoading = (): boolean => {
  const [isOptimizelyLoaded, setIsOptimizelyLoaded] = useState(false);

  useEffect(() => {
    optimizely.onReady().then(() => setIsOptimizelyLoaded(true));
  }, []);

  return isOptimizelyLoaded;
};
